<template>
  <div id="misc" class="d-flex align-center justify-center min-height-100">
    <v-card flat tile color="transparent" max-width="400" class="text-center">
      <v-img src="@/assets/images/logos/hatko-logo.png"></v-img>
      <div class="my-6">
        <h1 class="text-h2 text-md-h1">OOps!</h1>
        <h2 class="text-body-2 text-md-body-1 my-3">404 Hatası aradığınız sayfayı bulamadık</h2>
      </div>

      <v-btn color="secondary" rounded x-large outlined to="/" class="mb-4 text-capitalize ls-n1"> Ana Sayfa </v-btn>
    </v-card>
  </div>
</template>

<script>
import { mdiAlert } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAlert,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/misc.scss';
</style>
